import type { PageComponent } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { PageComponentProps } from '@/features/a-dynamic-page/components/page-component-switch';
import type {
    HeadlineV1_jsonld_page_read,
    HeadlineV1Attributes,
    HeadlineV1Meta,
} from '@/features/headline-v1/headline-v1-type';

import React from 'react';

import { Headline } from '@/core/features';
import useComponentDataByIri from '@/core/features/a-component/hooks/use-component-data-by-iri';
import { updateComponentIriParam } from '@/core/features/a-component/services/component-service';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { logger } from '@/core/features/logger/logger';
import { useAtomValue } from '@/core/features/store/atom-store';
import { headlineDesktopTitleVariants } from '@/features/headline-v1/headline-variants/headline-desktop-title-variants';
import { headlineSubtitleVariants } from '@/features/headline-v1/headline-variants/headline-subtitle-variants';
import { headlineTitleVariants } from '@/features/headline-v1/headline-variants/headline-title-variants';
import { mapBoundariesForComponentsAtom } from '@/features/map/map-data-v5/use-map-data-v5';

export type HeadlineV1Props = PageComponentProps<HeadlineV1Attributes, HeadlineV1Meta>;

export default function HeadlineV1({ attributes, meta }: HeadlineV1Props) {
    const { htmlTitle, subtitleStyle, subtitleType, titleStyle, titleType } = attributes;
    const headlineV1ByBoundaries = useHeadlingV1ByBoundaries<HeadlineV1_jsonld_page_read>(
        'HeadlineV1',
        meta?.boundariesRequestTarget,
    );
    const htmlSubtitle = headlineV1ByBoundaries?.attributes.htmlSubtitle || attributes.htmlSubtitle;

    const { isDesktopView } = useDeviceoutput();

    const titleVariant = React.useMemo(() => {
        const variant = isDesktopView ? headlineDesktopTitleVariants[titleStyle] : headlineTitleVariants[titleStyle];
        if (!variant) {
            logger.error(`Invalid titleStyle "${titleStyle}" in component HeadlineV1`);
        }
        return variant;
    }, [isDesktopView, titleStyle]);

    const subtitleVariant = React.useMemo(() => {
        const variant = subtitleStyle && headlineSubtitleVariants[subtitleStyle];
        if (htmlSubtitle && !variant) {
            logger.error(`Invalid subtitleStyle "${subtitleStyle}" in component HeadlineV1`);
        }
        return variant;
    }, [htmlSubtitle, subtitleStyle]);

    if (!titleVariant && !subtitleVariant) {
        return null;
    }

    return (
        <div className={'width-100'}>
            {titleVariant && (
                <Headline
                    htmlTitle={htmlTitle}
                    type={titleType}
                    {...titleVariant}
                />
            )}
            {htmlSubtitle && subtitleType && subtitleVariant && (
                <Headline
                    htmlTitle={htmlSubtitle}
                    type={subtitleType}
                    {...subtitleVariant}
                />
            )}
        </div>
    );
}

const useHeadlingV1ByBoundaries = <T extends PageComponent>(
    queryName: string,
    boundariesRequestTarget?: null | string,
) => {
    const mapBoundariesForComponents = useAtomValue(mapBoundariesForComponentsAtom);

    const updatedBoundariesRequestTarget = React.useMemo(() => {
        if (!boundariesRequestTarget) {
            return null;
        }

        if (!mapBoundariesForComponents) {
            return null;
        }

        return updateComponentIriParam('userQuery', boundariesRequestTarget, mapBoundariesForComponents).toString();
    }, [boundariesRequestTarget, mapBoundariesForComponents]);

    const { data } = useComponentDataByIri<T>(updatedBoundariesRequestTarget, {
        queryName,
    });

    return data;
};
